.page-container {
	width: 60%;
	min-height: calc(100vh - 60px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: $white;
	padding: 0 0 25px 0;
	margin-bottom: 0;

	@include respond-to('ipad') {
		min-width: 100vw;
		max-width: 100vw;
		padding: 0;
    }
}

.flow-questions-title {
	text-align: center;
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 10px;
}

.welcome-container,
.checking-container,
.overdrawn-container,
.employment-container,
.income-container,
.credit-range-container,
.acima-container,
.how-to-steps,
.benefits-container {
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
}

.checking-container,
.overdrawn-container,
.employment-container,
.income-container,
.acima-container,
.credit-range-container {
	border: solid 2px $light-grey;
	padding: 50px 100px;
	margin-top: 20px;
	@include respond-to('small') {
		padding: 0 10px;
		border: none;
    }
}

.offer-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	min-height: calc(100vh - 60px);
}

.section-container {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: 100vw;

	@include respond-to('small') {
		text-align: left;
		margin-top: 20px;
    }
}

.how-to-steps {
	margin-top: 20px;
	max-width: 100vw;
}

.title-text,
.title-text-offset {
	font-size: 1.6rem;
	font-weight: 600;
	line-height: 1.25;
	margin-bottom: 10px;
	padding: 0 15px;
	max-width: 750px;
	text-align: center;

	@include respond-to('ipad') {
		max-width: 100vw;
		font-size: 2rem;
	}
	
	@include respond-to('small') {
		font-size: 1.3rem;
		max-width: 100vw;
		text-align: left;
    }
}

.title-text-offset {
	text-align: left;
	font-size: 1.3rem;
	@include respond-to('ipad') {
		margin-left: 15%;
	}
	@include respond-to('small') {
		margin-left: unset;
    }
	
}

.content-text,
.content-text-offset {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	padding: 0 15px;
	max-width: 750px;
	@include respond-to('ipad') {
		margin: 0 15%;
	}
	@include respond-to('small') {
		margin: unset;
		line-height: 1.25;
    }
}

.content-text-offset {
	font-size: 0.8rem;
	margin-bottom: 15px;
	line-height: 1.75;
}

.content-subtext {
	@include respond-to('small') {
		margin-left: 15px;
    }
}