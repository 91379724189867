.approval-amount__countainer {
	background: rgba(39,170,225,1);
	background: -moz-radial-gradient(center, ellipse cover, rgba(39,170,225,1) 0%, rgba(255,255,255,0.65) 51%, rgba(252,254,255,0.51) 71%, rgba(252,254,255,0.31) 100%);
	background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(39,170,225,1)), color-stop(51%, rgba(255,255,255,0.65)), color-stop(71%, rgba(252,254,255,0.51)), color-stop(100%, rgba(252,254,255,0.31)));
	background: -webkit-radial-gradient(center, ellipse cover, rgba(39,170,225,1) 0%, rgba(255,255,255,0.65) 51%, rgba(252,254,255,0.51) 71%, rgba(252,254,255,0.31) 100%);
	background: -o-radial-gradient(center, ellipse cover, rgba(39,170,225,1) 0%, rgba(255,255,255,0.65) 51%, rgba(252,254,255,0.51) 71%, rgba(252,254,255,0.31) 100%);
	background: -ms-radial-gradient(center, ellipse cover, rgba(39,170,225,1) 0%, rgba(255,255,255,0.65) 51%, rgba(252,254,255,0.51) 71%, rgba(252,254,255,0.31) 100%);
	background: radial-gradient(ellipse at center, rgba(39,170,225,1) 0%, rgba(255,255,255,0.65) 51%, rgba(252,254,255,0.51) 71%, rgba(252,254,255,0.31) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27aae1', endColorstr='#fcfeff', GradientType=1 );
	padding: 20px 30px;
	font-size: 4rem;
	margin-bottom: 20px;
	font-weight: 500;
	text-align: center;
	@include respond-to('small') {
		font-size: 3rem;
		width: 100%;
    }
}

.next-steps__item {
	font-weight: 300;
	font-size: 1.1rem;
	@include respond-to('small') {
		margin-bottom: 10px;
    }
}

.shop-now__button {
	margin-bottom: 40px;
	@include button-reset;
	min-width: 310px;
	height: 65px;
	background-color: $brand-color;
	border-radius: 50px;
	text-transform: uppercase;
	color: $white !important;
	font-size: 1.5rem;
	transition: ease-in-out min-width 0.25s;
	display: flex;
	justify-content: center;
	align-items: center;
	
	@include respond-to('small') {
		
    }
}

.shop-now__button:hover {
	background-color: $hover-brand-color;
	color: $white;
	min-width: 340px;
	@include respond-to('small') {
		background-color: $brand-color;
    }
}