// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.flow-button-container {

};

.flow-button {
	@include button-reset;
	width: 250px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $brand-color;
	color: $white;
	border-radius: 25px;
	margin: 5px;
	font-size: 1rem;
}

.flow-button:hover {
	cursor: pointer;
	background-color: $hover-brand-color;
	color: $white;
	@include respond-to('small') {
		background-color: $brand-color;
    }
}


.pre-button {
	@include button-reset;
	min-width: 410px;
	height: 65px;
	background-color: $brand-color;
	border-radius: 50px;
	text-transform: uppercase;
	color: $white !important;
	font-size: 1.15rem;
	transition: ease-in-out min-width 0.25s;
	display: flex;
	justify-content: center;
	align-items: center;
	
	@include respond-to('small') {
		font-size: 1rem;
		min-width: 250px;
		padding: 10px;
    }
}

.pre-button:hover {
	cursor: pointer;
	background-color: $hover-brand-color;
	color: $white;
	min-width: 440px;
	@include respond-to('small') {
		background-color: $brand-color;
    }
}

.offer-button {
	@include button-reset;
	min-width: 325px;
	background-color: $hover-brand-color;
	color: $white;
	height: 90px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.5rem;
	transition: ease-in-out min-width 0.25s;
	border-radius: 90px;
	@include respond-to('small') {
		min-width: 80vw;
		font-size: 1.3rem;
		margin-bottom: 20px;
    }
}

.offer-button:hover {
	background-color: $hover-brand-color;
	cursor: pointer;
	color: $white;
	min-width: 350px;
	@include respond-to('small') {
		background-color: $brand-color;
		color: $white;
    }
}

.offer__button_single-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 50px 10px 10px;
}

.offer__button_single-subtitle {
    font-size: 0.8rem;
    font-weight: 300;
}

.offer-button-icon {
    font-size: 2.2rem;
    margin: 10px 10px 10px 50px;
}

.no-thanks-button {
	color: $brand-color;
	font-weight: 400;
	padding-top: 20px;
	transition: ease-in-out font-weight 0.25s;
	transition: ease-in-out font-size 0.25s;
	background-color: $white;
	font-size: 1.1rem;
	@include respond-to('small') {
		padding: 0;
    }
}

.no-thanks-button:hover {
	cursor: pointer;
	font-weight: 700;
	font-size: 1.2rem;
	color: $hover-brand-color;
	@include respond-to('small') {
		color: $brand-color;
		font-size: 0.8rem;
		font-weight: 400;
    }
}

.submit-btn {
    margin: 0;
    font-family: Helvetica,Arial,sans-serif;
    overflow: visible;
    -webkit-appearance: button;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid #007bff;
    padding: .375rem .75rem;
    font-size: 18px;
    line-height: 27px;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #fff;
    background-color: #007bff;
    display: block;
    width: 100%;
    margin-top: 1rem!important;
}