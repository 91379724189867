.question-text {
	font-size: 1.5rem;
	margin-bottom: 25px;

	@include respond-to('small') {
		font-size: 1.4rem;
		margin: 10px 30px 30px 30px;
    }
}

.question-count {
	color: $brand-color;
	font-size: 0.9rem;
	margin-bottom: 10px;
	@include respond-to('small') {
		margin-top: 20px;
    }
}

.chevron-lottie-carot {
	width: 10px;
	margin-bottom: 10px;
}