.footer-container {
	position: relative;
	bottom: 0;
	min-width: 100vw;
	max-width: 100vw;
	height: 150px;
	background-color: $footer-bg;
	color: $white;
	padding: 0px 20px;
	display: flex;
	@include respond-to('small') {
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		height: auto;
		padding: 0;
    }
}

.container-item {
	margin: 10px 0;
	width: 50%;
	padding: 30px;
	@include respond-to('ipad') {
		padding: 10px 0;
		min-width: 20vw;
		max-width: 100vw;
	}
	@include respond-to('small') {
		padding: 10px 0;
		width: 100vw;
		max-width: 100vw;
	}
}

.footer-left {
	display: flex;
	flex: 1;
	@include respond-to('small') {
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		height: auto;
		margin-top: 10px;
		max-width: 100vw;
    }
}

.footer-right {
	display: flex;
	flex: 1.5;
	justify-content: space-around;
	padding-right: 100px;
	@include respond-to('ipad') {
		padding-right: 10px;
    }
	@include respond-to('small') {
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		height: auto;
		padding: 0 0 0 20px;
		max-width: 100vw;
    }
}

.footer-right-child-text {
	font-size: 0.6rem;
	margin: 0 0 10px 0;
	@include respond-to('small') {
		font-size: 0.8rem;
    }
}

.footer-right-child-title {
	font-size: 0.9rem;
	font-weight: 600;
	@include respond-to('small') {
		font-size: 1rem;
	}
}

.footer-left-child {
	margin-left: 30px;
	font-size: 0.5rem;
	display: flex;
    flex-direction: column;
}

.footer-link {
	color: $white !important;
	font-size: 0.6rem;
}