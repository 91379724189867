.decline__container {
	padding: 50px 20px;
	margin: 20px 0;
	font-size: 1.5rem;
	max-width: 100vw;

	@include respond-to('small') {
		padding: 0 10px 10px 10px;
		font-size: 1.3rem;
    }
}

.decline__row {
	margin: 0 0 30px 0;
	@include respond-to('small') {
		margin-bottom: 20px;
    }
}