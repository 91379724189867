.navbar-container {
	position: fixed;
	top: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	min-width: 100vw;
	height: 50px;
	background-color: $white;
	padding: 0 20px;
	z-index: 1000;

	@include respond-to('ipad') {
        justify-content: space-between;
    }
}

.navbar-phone-container {
	font-weight: 300;
	font-size: 0.85rem;
	@include respond-to('large') {
        display: none
    }
}

.navbar-logo:hover {
	cursor: pointer;
}