.app-container {
	min-width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: $white;
	position: absolute;
	top: 50px;
	left: 0;
	right: 0;
	overflow-x: hidden;
}