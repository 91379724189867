.iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: calc(100vh - 40px);
	border: none;
}

#acima-form {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 0;
}

.acima-container {
	border: none;
}

.modal-container {
	background-color: rgba(70,68,68,.5);
	width: 100%;
	max-width: 100vw;
	min-height: fit-content;
    border: none;
    
}

.modal-position-frame {
	max-width: 600px;
    width: 100%;
    min-height: fit-content;
    padding: 20px 0;
    overflow: hidden;
    margin: auto;
    @include respond-to('small') {
        padding: 10px 0;
    }
}

.modal-form {
	position: relative;
    border: none;
    z-index: 1001;
    background-color: #fff;
    border-radius: .5rem;
    height: fit-content;
    @include respond-to('small') {
        height: fit-content;
    }
}

.modal-header {
	margin-bottom: 0;
    padding: 0.4rem 1.25rem;
    border-bottom: 1px solid rgba(0,0,0,.125);
	width: 100%;
	min-height: 50px;
    background-color: rgba(0,0,0,.03);
}

.close-button-modal {
	padding: 0;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.form-body {
	flex: 1 1 auto;
    -webkit-box-flex: 1;
    padding: 1.25rem;
    height: calc(100% - 20px);
    overflow-y: auto;
    @include respond-to('small') {
        max-height: unset;
    }
}

.form-body-container {
	width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.modal-title {
	font-weight: 500;
    font-size: 1.4rem;
    line-height: 20px;
    color: #004987;
    margin-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0,0,0,.1);
    text-transform: uppercase;
}

.capture-info-form {
    display: block;
    margin-top: 0;
    -webkit-margin-after: 1em;
    margin-block-end: 1em;
}

.form-helper-text {
    margin-bottom: .75rem;
}

.form-helper-label {
    display: inline-block;
    margin-bottom: .5rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 12px;
    color: #004987;
}

.form-control-input {
    margin: 0;
    overflow: visible;
    display: inline-block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 16px;
    line-height: 27px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem!important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control-input::placeholder {
    color: $light-grey;
}

.form-check-label {
    display: inline-block;
    margin-bottom: 0;
    padding: 5px;
}

.form-check-input {
    margin: .15rem 0 0 -1.25rem;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow: visible;
    position: absolute;
    z-index: 1010;
}

.form-check-policy {
    position: relative;
    display: block;
    font-size: .75rem;
    color: #393b3c;
}

#phone-policy {
    // margin-left: 20px;
}