.brandbar-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 100vw;
	height: auto;
	background-color: $dark-blue;
	color: $white;
	padding-top: 10px;
	margin-bottom: 60px;
	@include respond-to('small') {
		margin: 30px 0;
		max-width: 100vw;
    }
}

.brandbar-title {
	text-transform: uppercase;
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 10px;
}

.carousel-container {
	display: inline-flex;
	align-items: center;
	justify-content: space-around;
	max-width: 1100px;
	height: 85px;
	padding: 0 20px;
	width: 100%;

	@include respond-to('small') {
		justify-content: space-between;
		max-width: 100vw;
		display: inline-flex !important;
		align-items: center;
		justify-content: space-around;
    }
}

.brand-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 0.75rem;
}

.slide-arrow {
	@include respond-to('large') {
		display: none;
    }
}

.mobile-brands {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 0 15px;
	margin: 10px 0;
	@include respond-to('large') {
		display: none;
    }
}

.desktop-brands {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 10px 0;
	@include respond-to('small') {
		display: none;
    }
}

.brand-item-text {
	padding-top: 5px;
	margin-bottom: 20px;
}