.benefits-container {
	padding: 10px;
	margin-top: 20px;
}

.benefits-mobile {
	@include respond-to('large') {
		display: none;
    }
}

.benefits-desktop {
	display: flex;
	flex-direction: column;

	@include respond-to('small') {
		display: none;
    }
}

.benefits-left-container {
	display: flex;
	// min-height: 500px;
	flex: 1;
	justify-content: flex-end;
}

.benefits-right-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 50px 0;
	flex: 1;
	max-width: 400px;

	@include respond-to('small') {
		max-width: 100vw;
    }
}

.benefit {
	text-align: left;
	padding: 20px 0;
}

.web-benefits-container {
	width: 70vw;
	min-height: 500px;
	margin-bottom: 100px;
	display: inline-flex;
}

.fine-print {
	text-align: center;
	font-size: 0.8rem;
}