// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------





/// Regular font family
/// @type List
$text-font-stack: 'Roboto', 'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default;

/// Code (monospace) font family
/// @type List
$code-font-stack: 'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default;





/// PreApproval Button
/// @type Color
$pre-button: rgb(35, 160, 221) !default;


/// White
/// @type Color
$white: rgb(255,255,255) !default;

/// Copy text color
/// @type Color
$text-color: rgb(34, 34, 34) !default;

/// Main brand color
/// @type Color
$brand-color: #27aae1 !default;

/// hover brand color
/// @type Color
$hover-brand-color: rgb(6, 73, 189) !default;

/// dark blue
/// @type Color
$dark-blue: rgb(0,37,52) !default;

/// Light grey
/// @type Color
$light-grey: rgb(226, 225, 225) !default;

/// Medium grey
/// @type Color
$mid-grey: rgb(190, 190, 190) !default;

/// Dark grey
/// @type Color
$dark-grey: rgb(68, 68, 68) !default;

/// Footer 
/// @type Color
$footer-bg: rgb(0,37,52) !default;





/// Container's maximum width
/// @type Length
$max-width: 1180px !default;





/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': (max-width: 500px),
  'ipad': (max-width: 1000px),
  'large': (min-width: 1001px),
) !default;






/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $base-url: 'http://cdn.example.com/assets/';
$base-url: '/assets/' !default;
