.did-you-know-container {
	background: rgb(250,252,252);
	background: radial-gradient(circle, rgba(250,252,252,0.7553396358543417) 0%, rgba(245,248,249,0.2511379551820728) 50%, rgba(39,170,225,0.500437675070028) 76%, rgba(39,170,225,0.7357317927170868) 84%, rgba(39,170,225,1) 100%);
	padding: 5px 25vw;
	min-width: 100vw;
	display: inline-flex;
	align-items: center;
	@include respond-to('small') {
		padding: 15px 10px;
		background: radial-gradient(circle, rgba(250,252,252,0.7553396358543417) 0%, rgba(245,248,249,0.2511379551820728) 70%, rgba(39,170,225,0.500437675070028) 96%, rgba(39,170,225,0.7357317927170868) 100%, rgba(39,170,225,1) 100%);
    }
}

.did-you-know-text {
	font-size: 1.1rem;
	text-align: left;
	margin: 10px 25px 10px -70px;
	@include respond-to('ipad') {
		margin: 0 10px 0 30px;
		font-size: 1rem;
    }
	// @include respond-to('small') {
	// 	margin: 0 0 0 10px;
	// 	font-size: 1rem;
    // }
}

.did-you-text-start {
	font-weight: bold;
}

.offer-question-container {
	font-size: 1.5rem;
	padding: 30px 25vw;
	margin: 5vh 0;
	overflow-wrap: break-word;
	@include respond-to('small') {
		padding: 25px;
		font-size: 1.4rem;
		text-align: left;
		margin: 2vh 0;
    }
}

.did-you-know-icon {
	font-size: 2rem;
	@include respond-to('ipad') {
		font-size: 3rem;
    }
}

.loading-offer-container {
	min-height: calc(100vh - 60px);
	min-width: 100vw;
}
