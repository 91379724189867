.howto-container {
	padding: 0;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include respond-to('small') {
		text-align: left;
    }
}

.howto-preapproval-button {
	margin: 60px 0 10px 0;
	@include respond-to('small') {
		margin: 10px 0 0 0;
    }
}