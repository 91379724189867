.mobile-steps-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 100vw;
	@include respond-to('large') {
		display: none;
    }
}

.web-steps-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 1000px;

	@include respond-to('small') {
		display: none;
    }
}

.step-row {
	display: flex;
	justify-content: center;
	width: 900px;

	@include respond-to('small') {
		width: 90vw;
    }
}

.step {
	margin: 10px;
	position: relative;
}

.step-img {
	max-width: 35vw;
}

.step-img-mobile {
	margin-bottom: 10px;
}